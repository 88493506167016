<template>
  <div>
    <b-alert variant="danger" :show="error.length > 0">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrió un error al obtener los promocodes {{ error }}
      </div>
    </b-alert>

    <b-card no-body>
      <b-card-header>
        <b-card-title>Promocodes</b-card-title>
        <crear-modal :callback="getPromocodes" tipoPromo="PROCO" />
      </b-card-header>

      <b-card-body>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Buscar</label>
              <b-form-input
                type="text"
                class="d-inline-block"
                @input="onSearch"
                placeholder="Buscar"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          mode=""
          :rows="rows"
          :columns="columns"
          :totalRows="totalRecords"
          :isLoading.sync="loading"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :sort-options="{
            enabled: false,
          }"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="emptystate">No hay promocodes para mostrar</template>

          <template slot="table-row" slot-scope="props">
            <!-- Column: Fecha inicio -->
            <span v-if="props.column.field === 'prFechaInicio'">
              <p class="mb-0"><small>{{ formattedDate(props.row.prFechaInicio) }}</small></p>
              <p class="mb-0"><small>{{ formattedHour(props.row.prHoraInicio) }}</small></p>
            </span>
            <!-- Column: Fecha fin -->
            <span v-else-if="props.column.field === 'prFechaFin'">
              <p class="mb-0"><small>{{ formattedDate(props.row.prFechaFin) }}</small></p>
              <p class="mb-0"><small>{{ formattedHour(props.row.prHoraFin) }}</small></p>
            </span>
            <!-- Column: Estado -->
            <span v-else-if="props.column.field === 'prActivo'">
              <b-badge :variant="statusVariant(props.row.prActivo)">
                {{ statusText(props.row.prActivo) }}
              </b-badge>
            </span>
            <!-- Column: Cant. usos -->
            <span v-else-if="props.column.field === 'usos'">
              <p class="mb-0"><small>Max promoción:</small> <b>{{ props.row.prCantidadMaximaPromocion }}</b></p>
              <p class="mb-0"><small>Max por cliente:</small> <b>{{ props.row.prCantidadUsoUsuario }}</b></p>
            </span>
            

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="editPromo($event, props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="confirmDeletePromo($event, props.row)"
                  >
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Eliminar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Otras Columnas -->
            <span v-else>
              <small>
                {{ props.formattedRow[props.column.field] }}
              </small>
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Cantidad de Promocodes </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '15', '20']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
              </div>
              <div>
                <b-pagination
                  align="right"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                  :value="1"
                  :per-page="pageLength"
                  last-number
                  :total-rows="props.total"
                  first-number
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>

      <b-modal
        title="Error"
        ok-only
        v-model="showErrorModal"
        centered
        ok-title="Accept"
        ok-variant="danger"
        modal-class="modal-danger"
      >
        <b-card-text>
          {{ error }}
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BBadge,
  BButton,
  BAvatar,
  BSpinner,
  BCardBody,
  BDropdown,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BCardHeader,
  BDropdownItem,
} from "bootstrap-vue";
import moment from "moment";
import CrearModal from "./CrearPromo.vue";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BBadge,
    BButton,
    BAvatar,
    BSpinner,
    BCardBody,
    BDropdown,
    BCardText,
    CrearModal,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    BCardHeader,
    VueGoodTable,
    BDropdownItem,
    ToastificationContent,
  },
  data() {
    return {
      page: 1,
      rows: [],
      error: "",
      loading: true,
      searchTerm: "",
      pageLength: 10,
      totalRecords: 0,
      seachTimeout: null,
      showErrorModal: false,
      columns: [
        {
          label: "Nombre",
          field: "prNombre",
        },
        {
          label: "Descripción",
          field: "prDescripcion",
        },
        {
          label: "Código",
          field: "prCodigo",
        },
        {
          label: "Cant. Usos",
          field: "usos",
        },
        {
          label: "Inicio",
          field: "prFechaInicio",
        },
        {
          label: "Fin",
          field: "prFechaFin",
        },
        {
          label: "Estado",
          field: "prActivo",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },

  mounted() {
    this.getPromocodes();
  },
  computed: {
    statusText() {
      const status = {
        true: "Activo",
        false: "Inactivo",
      };
      return (s) => status[s];
    },
    statusVariant() {
      const statusColor = {
        false: "light-warning",
        true: "light-success",
      };
      return (s) => statusColor[s];
    },
  },
  methods: {
    onSearch(query) {
      //this.loading = true;
      this.searchTerm = query;
      clearTimeout(this.seachTimeout);
      //this.seachTimeout = setTimeout(this.getPromocodes, 2500);
    },
    onPageChange(params) {
      this.page = params.currentPage;
      //this.getPromocodes();
    },
    onPerPageChange(params) {
      this.pageLength = params.currentPerPage;
      //this.getPromocodes();
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalHour) {
      return moment(originalHour, "HH:mm:ss").format("hh:mm a");
    },
    editPromo(event, promo) {
      event && event.preventDefault();
      this.$router.push({
        name: "promocode",
        params: { id: promo.prIdpromocion, promo: promo },
      });
    },
    confirmDeletePromo(event, promo) {
      event && event.preventDefault();
      this.$bvModal
        .msgBoxConfirm(`Desea eliminar el promocode [${promo.prCodigo}]?.`, {
          size: "sm",
          title: "Confirmacion",
          okTitle: "Eliminar",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) this.deletePromo(promo);
        });
    },
    getPromocodes() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/ListaPromoCode",
          body: JSON.stringify({
            filas: this.pageLength,
            pagina: this.page,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.rows = res.data.lsPromociones;
            //this.totalRecords = res.data[0].totalpaginas;
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
          this.showErrorModal = true;
        });
    },
    deletePromo(promo) {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/EstatusPromocion",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
            IDPromocion: promo.prIdpromocion,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.getPromocodes();
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se elimino correctamente el Promocode`,
                title: "Exito",
              },
              component: ToastificationContent,
            });
          } else {
            this.$toast({
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error al eliminar el Promocode (${res.data.mensaje}).`,
                title: "Error",
                variant: "danger",
              },
              component: ToastificationContent,
            });
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al eliminar el Promocode (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        });
    },
  },
};
</script>

<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
